
import React, { Component } from 'react';
import {Logo,HeaderContainer,ContainerColumn,Title,Description,ModalContainer,Button} from '../styled'
import camera from '../assets/camera.svg'

class Modal extends Component {

    render() {
        return (
            <ModalContainer>
                <HeaderContainer>
                     <Logo src={camera} /> 
                </HeaderContainer>
                <ContainerColumn padding={"0vw 4vw"} >
                    <Title >No pudimos acceder a tu cámara</Title>
                    <Description style={{flexDirection: 'row', flexWrap: "wrap" }}>
                       Necesitamos validar tu identidad. Por Favor, danos permiso en tu navegador
                    </Description>
                    <Button onClick={this.props.onClose}>Aceptar</Button>
                </ContainerColumn>
          </ModalContainer>
        )
    }
  }

export default Modal;
