
import styled from 'styled-components'

export const Container = styled.div`   
   height: auto;
`
export const EmptyPage = styled.img`
    @media (min-width: 1024px) {
        justify-content: center;
        padding: 1% 30%;
    }
    width: 30vw;
    padding: 15% 35%;
`
export const Title = styled.div`
    color: ${props => props.color ? props.color : "#30735f"};
    width: 90vw;
    font-size: 25px;
    font-weight: 700;
`
export const HeaderContainer = styled.div`
    @media (min-width: 1024px) {
        justify-content: center;
        margin: 2vw;
    }
    display: flex;
    flex-direction: initial;
`
export const ContainerRow = styled.div`
    @media (min-width: 1024px) {
        width: 45vw;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`
export const ContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    padding:${props => props.padding ? props.padding : "0px"};
    margin: ${props => props.margin ? props.margin : "0px"};
    background-color: ${props => props.backgroundcolor ? props.backgroundcolor : "white"};;
    width: ${props => props.width ? props.width : ""};
`
export const ContainerColumnFooter = styled.div`
    @media (min-width: 1024px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: ${props => props.width ? props.width : "55vw"};
    }

    display: flex;
    flex-direction: column;
    text-align: center;
    padding:${props => props.padding ? props.padding : "0px"};
    margin: ${props => props.margin ? props.margin : "0px"};
    background-color: ${props => props.backgroundcolor ? props.backgroundcolor : "#f6f6f6"};
    width: ${props => props.width ? props.width : ""};
`
export const Description = styled.div`
    
    @media (min-width: 1024px) {
        justify-content: center;
        margin: 1% 0% 2% 0%;
    }
    display: flex;
    font-size: 19px;
    color: ${props => props.color ? props.color : "#000000"};
    font-weight: ${props => props.bold ? "700" : "400"};
    text-align: center;
    margin: 4% 0%;
    justify-content: center;
`
export const DescriptionFooter = styled.div`
    @media (min-width: 1024px) { 
        width: 30vw;
    }
    font-size: 19px;
    color: #000000;
    font-weight: ${props => props.bold ? "500" : "400"};
    text-align: center;
    margin: 3% 3%;
`
export const TitleFooter = styled.div`
    color: #30735f;  
    font-size: 20px;  
    font-weight: 500;
    text-align: center;
    margin: 4% 0%;
`
export const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
`
export const Logo = styled.img` 

@media (min-width: 1024px) {
    width: 10vw;
    margin: 0px;
    padding: 0vw;
  }
    width: 30vw;
    margin: 30% 30% 5% 30%;
    padding: 5vw;
`
export const LoaderImg = styled.img`
    width: 15vw;
`
export const LogoFooter = styled.img`
    @media (min-width: 1024px) {
        width: 15vw;
    }
    width: 40vw;
`
export const Success = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.green};
`

export const ModalContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top:0;
`
export const FormContainer = styled.form`
    @media (min-width: 1024px) {
    width: 55vw;
    margin: 0% 22%;
  }
  width: 90vw;
  justify-content: center;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;    
  margin: 0vw 5vw;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease;
`
export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.green};
    align-items: ${({ align }) => align || 'flex-start'};
    justify-content: ${({ justify }) => justify || 'space-between'};
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)};
    margin-bottom: ${({ error }) => (error ? 30 : 20)}px;
`
export const InputWrapper = styled.div`
    width:  100%;
    height: 9vh;
    display:  ${({ display }) => display || 'flex'};
    flex-direction: row;
    justify-content: space-evenly;
`

export const SelfieWrapper = styled.div`
    width:  100%;
    height: auto;
    display:  ${({ display }) => display || 'flex'};
    color: ${({ theme }) => theme.colors.green};
    flex-direction: row;
    justify-content: center;  
    border-radius: 6px;
    border: solid 1px #dde7e8;
`
export const InputLabel = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.green};
    font-size: 20px;
    padding-bottom: 10px;
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`

export const StyledButton = styled.button`
  font-family: ${({ theme }) => theme.fonts.roboto};
  background-color: #dde7e8;
  color: #627176;  
  border-radius: 6px;
  border: solid 1px #30735f;
  font-size: 18px;
  font-weight: 500;
  width: 80vw;
  padding: 20px 10px;
  cursor: pointer;
  outline: none;
`

export const Button = styled.button`
    @media (min-width: 1024px) {
            width: 20vw;
            margin: 2%;
        }
    font-family: ${({ theme }) => theme.fonts.roboto};
    background-color: #30735f;
    color: white;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 500;
    width: 60vw;
    padding: 10px 5px;
    align-self: center;
    margin: 4%;
    cursor: pointer;
    outline: none;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`
export const FileInputStyled = styled.div`
    width: 80px;
    height: 80px;
    border-color: ${({ theme }) => theme.colors.green};
    border-style: dotted;
    border-width: 1px;
    border-radius: 10px;
    
`