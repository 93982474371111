
import React, { Component } from 'react'; 
import Header from './components/Header';
import Form from './components/Form';
import Footer from './components/Footer';
import Success from './components/Success';
import Modal from './components/Modal';
import loader from './assets/loader.gif';
import './App.css';
import { theme } from './constants/theme'
import {  ThemeProvider } from 'styled-components'
import {  Container,EmptyPage,Title,ContainerColumn } from './styled.js'
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  return ( 
    <Router>
      <ThemeProvider theme={theme}>
        <Container>
          <QueryComponent />
        </Container>
      </ThemeProvider>
    </Router>
  )
}

function QueryComponent() {
  let query = useQuery(); 
  let idInvitation;

  if (query.get("invitation")){
    idInvitation = query.get("invitation").replace(" ", "+")
    return ( 
      <RenderPage invitation={idInvitation} />
    )
  }else{ 
    return ( 
      <ContainerColumn width="100%">
        <EmptyPage src={loader} />
        <Title>Debe ingresar con una invitacion</Title>
      </ ContainerColumn>
    )
  }
  
}

class RenderPage extends Component {
  state={
    cameraShow: false,
    data: "",
    photo: false,
    isFetching: true,
    success: false,
    error: false
  }

  componentDidMount(){
    let idInvitation = this.props.invitation;
    let response = this.fetchUsersAsync(idInvitation);
  }

  async fetchUsersAsync(idInvitation) { 
    try {
        this.setState({...this.state, isFetching: true});
        let url = `https://72o9de1ute.execute-api.us-east-2.amazonaws.com/Staging/api/invites/gethostinfo/`
        let dataFormatter = {
          invitationid: idInvitation
        }
        fetch(url,{
          method: 'post',
          body: JSON.stringify(dataFormatter),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then(response => response.json())
        .then(result => {
            this.setState({data: result, isFetching: false})
        })
    } catch (e) {
        console.log(e);
        this.setState({...this.state, isFetching: false});
    }
  };

  toggleModal(){    
    this.setState({cameraShow: !this.state.cameraShow})
  }

  handleTakePhoto (dataUri) {
    this.setState({photo: dataUri})
    this.toggleModal()
  }

  handleSuccess(message) {
    this.setState({success: message.success})
    this.setState({message: message.message})
  }

  errorCamera(){
    this.setState({cameraShow: false})
    this.setState({error: !this.state.error})
  }

  render(){ 
  if(this.state.error){
    return(
      <Modal onClose={()=> this.errorCamera()} />
    )
  }else {
  if(this.state.isFetching){
    return (
      <>
      <ContainerColumn width="100%">
        <EmptyPage src={loader} />
      </ContainerColumn>
      </>
    )}else{
      return (
        <>
          <div 
            style={{
              visibility: this.state.cameraShow || !this.state.success === false ? "hidden" : "visible",
              position: this.state.cameraShow || !this.state.success === false  ? "absolute" : "",
              top: this.state.cameraShow || !this.state.success === false ? "-1500px" : "",
            }}
            >
            <Header data={this.state.data} />
            {this.state.data.success === false ? (
              <></>
            ) : (
                <Form photo={this.state.photo} invitation={this.props.invitation} cameraShow={()=>this.toggleModal()} onSuccess={(result)=> this.handleSuccess(result)} />
            )}
            <Footer />
          </div>
          {this.state.cameraShow ? 
            <Camera
             onCameraError=  { (error) => { this.errorCamera(error); } } 
              onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
              isFullscreen={true}
            />
          : <></>}
          {this.state.success ? 
            <Success urlQr={this.state.success.url_qr}/>
          : <>{this.state.message}</>}
          
        </>
      )
    }
  }
  }
}

export default App;
