
import React, { Component } from 'react';
import {ContainerColumnFooter,ContainerRow,TitleFooter,DescriptionFooter,LogoFooter} from '../styled'
import mano from '../assets/manito.svg'
import apple from '../assets/apple.svg'
import android from '../assets/android.svg'

class Footer extends Component {

    render() {
        return (
            <ContainerColumnFooter margin={"4% 0% 0% 0%"} padding={"5% 0%"} width={"100%"}>
                <img src={mano} alt={"selfie"}/>
                <DescriptionFooter bold={true}>
                    También podés descargarte Miiii app
                    para poder acceder al barrio y descubrir
                    todos los beneficios. 
                </DescriptionFooter>
                <ContainerColumnFooter>
                    <TitleFooter bold={true}>Descargala gratis acá:</TitleFooter>
                    <ContainerRow>
                        <a href="https://apps.apple.com/ar/app/miiii/id1460141483?l=en">
                            <LogoFooter src={apple}  />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=app.miiii.mibarrioapp">
                            <LogoFooter src={android}  />
                        </a>
                    </ContainerRow>
                </ContainerColumnFooter>
          </ContainerColumnFooter>
        )
    }
  }

export default Footer;
