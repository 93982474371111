
import React, { Component } from 'react';
import {Success,HeaderContainer,ContainerColumn,Title,Description,Logo} from '../styled'
import tick from '../assets/tick.svg'

class SuccessComponent extends Component {

    render() {
        return (
            <Success>
                <HeaderContainer>
                    <Logo src={tick} />
                </HeaderContainer>
                <ContainerColumn padding={"5vw"} backgroundcolor={"#30735f"}>
                    <Title color={"white"}>¡Perfecto!</Title>
                    <Description color={"white"} style={{flexDirection: 'row', flexWrap: "wrap" }}>
                        Ahora revisá tu casilla de correo con el email que te hemos enviado para que puedas acceder al barrio.
                    </Description>
                    
                {/* 
                //si hay que mostrar el QR aca esta
                <HeaderContainer>
                    <Logo src={this.props.urlQr} />
                </HeaderContainer> */}
                </ContainerColumn>
          </Success>
        )
    }
  }

export default SuccessComponent;
