import styled from 'styled-components'

export const FileInputWrapper = styled.div`
  color: ${({ theme }) => theme.colors.green};  
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`

export const StyledFileInput = styled.input`
  font-size: 200px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
`

export const ImageAttachText = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 20px;
`

export const AttachedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.transparent};
  padding: 1%;
`

export const CheckedElement = styled.img`
  position: absolute;    
  right: 10%;
  border: 2px solid ${({ theme }) => theme.colors.white};
  width: 28px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`

export const DocumentImg = styled.img`
  width: 25%;
  margin: 5%;
  border-radius: 8px;
`

export const SelfieImg = styled.img`
  width: 75%;
  margin: 5%;
  border-radius: 8px;
`
export const Modal = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 9999;
`
export const StyledButton = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.green};  
  border-radius: 6px;
  border: solid 1px #30735f;
  font-size: 18px;
  font-weight: 500;
  width: 50vw;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  outline: none;
`