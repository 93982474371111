
import React, { Component } from 'react';
import {ContainerColumn,HeaderContainer,Title,Description,Logo} from '../styled'
import logo from '../assets/logo.svg'
import dayjs from 'dayjs'

dayjs.locale('es')

const dateParse = (day) => {
    const date = dayjs(day)
    return date.format('DD/MM')
  }

class Header extends Component {

    render() {
        return (
            <ContainerColumn>
                <HeaderContainer>
                    <Logo src={logo} />
                </HeaderContainer>
                {this.props.data.success === false ? (
                    <ContainerColumn padding={"0vw 5vw"}>
                    <Title>{this.props.data.message}</Title>
                    </ContainerColumn>
                    ) : (
                        <ContainerColumn padding={"0vw 5vw"}>
                        <Title>¡Recibiste una invitación!</Title>
                        <Description  style={{flexDirection: 'row', flexWrap: "wrap" }}>
                            <Description>{"Recibiste una invitación de "}</Description>&nbsp;
                            <Description bold={true}>{this.props.data.username_name_owner}</Description> &nbsp;
                            <Description>{"para el barrio "}</Description>&nbsp;
                            {this.props.data.neighbourhoodparent_name ? (
                                <Description bold={true}>{this.props.data.neighbourhood_name +" ("+this.props.data.neighbourhoodparent_name +") " }</Description>
                            ) : (
                                <Description bold={true}>{this.props.data.neighbourhood_name}</Description>
                             )
                            }&nbsp;
                            <Description>{" para el día "}</Description>&nbsp;
                            <Description bold={true}>{dateParse(this.props.data.invitationdate_to)}</Description>
                        </Description>
                    </ContainerColumn>
                    )
                }
            </ContainerColumn>
                
        )
    }
  }

export default Header;
