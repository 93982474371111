import React from 'react'
import selfie from '../../assets/selfie.svg'
import CheckedImg from '../../assets/checked.png'
import 'react-html5-camera-photo/build/css/index.css';
import {
  AttachedContainer,
  CheckedElement,
  DocumentImg,
  FileInputWrapper,
  SelfieImg,
  StyledButton
} from './styled'

class FileInput extends React.Component {
  state = {
    photo: false,
    loading: false,
    modalShow: false
  }

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) this.setState({ url: this.props.image })
  }


  toggleModal(){
    this.props.cameraShow()
  }

  render() {
    return (
      <>
        <FileInputWrapper>
          <AttachedContainer>
            {this.props.photo !== false ? <CheckedElement src={CheckedImg} /> : ""}

            {this.props.photo === false ? 
              <DocumentImg src={selfie} /> 
            : 
              <SelfieImg src={this.props.photo} />
            }
            <StyledButton onClick={this.props.cameraShow} >Tomar foto</StyledButton>
          </AttachedContainer>
        </FileInputWrapper>  
      </>
    )
  }
}

export default FileInput
